import Container from '@/shared/components/Container'
import React from 'react'
import styles from './Acknowledgements.module.scss'

const Acknowledgements = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <ul className={styles.list}>
          <li className={styles.listItem}>Header picture by <a href="https://unsplash.com/@photohunter?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jimmy  Chang</a> in <a href="https://unsplash.com/es/fotos/wqBeoCtU_Ic?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></li>
          <li className={styles.listItem}>About us picture by <a href="https://unsplash.com/@anniespratt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Annie Spratt</a> in <a href="https://unsplash.com/es/fotos/QckxruozjRg?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></li>
        </ul>
      </Container>
    </div>
  )
}

export default Acknowledgements